.slider-item {
  width: 100%;
  height: 30vh;
  border-radius: 5px;
  @media screen and (min-width: 768px) {
    height: 35vh;
    object-fit: cover;
    object-position: center center;
  }
}
// .row-1 {
//   display: flex;
// }
// .row-1 .Card {
//   margin: 10px;
// }
