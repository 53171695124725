$theme-color-1: #11e967cf !default;
$theme-color-2: rgb(8, 205, 90) !default;
$theme-color-3: #08ad60 !default;
$button-color-1: #08cd1f !default;
$button-color-2: #53926c !default;

$theme-white: #fff !default;
$theme-black: #000 !default;

$theme-gray-1: #111 !default; //bg-theme-gray-1
$theme-gray-2: #222 !default;
$theme-gray-3: #333 !default;
$theme-gray-4: #444 !default;
$theme-gray-5: #555 !default;
$theme-gray-6: #666 !default;
$theme-gray-7: #777 !default;
$theme-gray-8: #888 !default;
$theme-gray-9: #999 !default;
$theme-gray-10: #aaa !default;
$theme-gray-11: #bbb !default;
$theme-gray-12: #ccc !default;
$theme-gray-13: #ddd !default;
$theme-gray-14: #eee !default;
$theme-gray-15: #fff !default;

$theme-colors: () !default;

$theme-colors: (
  "theme-white": $theme-white,
  "theme-black": $theme-black,
  "theme-gray-1": $theme-gray-1,
  "theme-gray-2": $theme-gray-2,
  "theme-gray-3": $theme-gray-3,
  "theme-gray-4": $theme-gray-4,
  "theme-gray-5": $theme-gray-5,
  "theme-gray-6": $theme-gray-6,
  "theme-gray-7": $theme-gray-7,
  "theme-gray-8": $theme-gray-8,
  "theme-gray-9": $theme-gray-9,
  "theme-gray-10": $theme-gray-10,
  "theme-gray-11": $theme-gray-11,
  "theme-gray-12": $theme-gray-12,
  "theme-gray-13": $theme-gray-13,
  "theme-gray-14": $theme-gray-14,
  "theme-color-1": $theme-color-1,
  "theme-color-2": $theme-color-2,
  "theme-color-3": $theme-color-3,
);

$button-color: () !default;
$button-color: (
  "button-color-1": $button-color-1,
  "button-color-2": $button-color-2,
);

$color-set: join($theme-colors, $button-color);
