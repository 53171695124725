:root {
  --theme-font-1: "Ubuntu", sans-serif;
  --theme-font-2: "Raleway", sans-serif;
  --theme-font-3: "Open Sans", sans-serif;
}

@each $name, $color in $color-set {
  .bg-#{$name} {
    background: $color !important;
  }

  .bg-hover-#{$name}:hover {
    background: $color !important;
  }

  .color-#{$name} {
    color: $color !important;
  }

  .color-hover-#{$name}:hover {
    color: $color !important;
  }

  .border-#{$name} {
    border: 1px solid $color !important;
  }

  .bt-#{$name} {
    border-top: 1px solid $color !important;
  }

  .br-#{$name} {
    border-right: 1px solid $color !important;
  }

  .bb-#{$name} {
    border-bottom: 1px solid $color !important;
  }

  .bl-#{$name} {
    border-left: 1px solid $color !important;
  }
  .btn-#{$name} {
    background: $color;
  }
}

$fz: 0.6;
@for $inc from 1 through 10 {
  .font-size-#{$inc}x {
    font-size: $fz + em;
  }

  $fz: $fz + 0.3;
}

$fw: 100;
@for $inc from 1 through 9 {
  .font-weight-#{$inc}x {
    font-weight: $fw;
  }

  $fw: $fw + 100;
}

$elem-w: 0;
@for $inc from 1 through 100 {
  .elem-w-#{$elem-w} {
    width: #{$elem-w}px;
  }
  .elem-h-#{$elem-w} {
    height: #{$elem-w}px;
  }
  .square-#{$elem-w} {
    height: #{$elem-w}px;
    width: #{$elem-w}px;
  }
  .elem-min-w-#{$elem-w} {
    min-width: #{$elem-w}px;
  }
  .elem-min-h-#{$elem-w} {
    min-height: #{$elem-w}px;
  }
  .elem-w-auto {
    width: auto;
  }
  .elem-h-auto {
    height: auto;
  }
  .square-auto {
    height: auto;
    width: auto;
  }
  $elem-w: $elem-w + 5;
}
@each $key, $var in $grid-min-breakpoints {
  @media screen and (min-width: $var) {
    $elem-w: 0;
    @for $inc from 1 through 100 {
      .elem-min-#{$key}-w-#{$elem-w} {
        width: #{$elem-w}px;
      }
      .elem-min-#{$key}-h-#{$elem-w} {
        height: #{$elem-w}px;
      }
      .square-min-#{$key}-#{$elem-w} {
        height: #{$elem-w}px;
        width: #{$elem-w}px;
      }
      $elem-w: $elem-w + 5;
    }
    .elem-min-#{$key}-w-auto {
      width: auto;
    }
    .elem-min-#{$key}-h-auto {
      height: auto;
    }
    .square-min-#{$key}-auto {
      height: auto;
      width: auto;
    }
  }
}
@each $key, $var in $grid-max-breakpoints {
  @media screen and (max-width: $var) {
    $elem-w: 0;
    @for $inc from 1 through 100 {
      .elem-max-#{$key}-w-#{$elem-w} {
        width: #{$elem-w}px;
      }
      .elem-max-#{$key}-h-#{$elem-w} {
        height: #{$elem-w}px;
      }
      .square-max-#{$key}-#{$elem-w} {
        height: #{$elem-w}px;
        width: #{$elem-w}px;
      }
      $elem-w: $elem-w + 5;
    }
    .elem-max-#{$key}-w-auto {
      width: auto;
    }
    .elem-max-#{$key}-h-auto {
      height: auto;
    }
    .square-max-#{$key}-auto {
      height: auto;
      width: auto;
    }
  }
}
