.br-bottom {
  border-bottom-right-radius: 14px !important;
  border-bottom-left-radius: 14px !important;
}

.br-top {
  border-top-right-radius: 14px !important;
  border-top-left-radius: 14px !important;
}

.theme-shadow {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.c-pointer {
  cursor: pointer;
}

.layer-top {
  z-index: 9;
}

.append-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
