@import "custom.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import "./scss_inc/theme-mixins";
@import "./scss_inc/theme-variables";
@import "./scss_inc/theme-commons";
@import "./scss_inc/theme-color";
@import "./scss_inc/theme-executable";
@import "./scss_inc/theme-typography";

body,
html {
  background-color: $theme-color-3;
}

.form-control {
  border: 1px solid $theme-color-1;
  &:focus {
    box-shadow: rgba(21, 160, 75, 0.4) 0px 0px 0px 2px,
      rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
      rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  }
}

.main-wrapper {
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
  box-shadow: 0 0 15px 5px rgb(0 0 0 / 10%);
  height: 94vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
  background: $theme-color-3;
}

.errorMsg {
  font-size: 12px;
  padding: 3px;
  color: tomato;
  font-weight: 500;
}

.was-validated .form-control:invalid {
  padding-right: 0.75em !important;
}
// .navbar-light .navbar-toggler-icon {
//   background-image: url(../images/menu.png);
//   width: 1.3em;
// }

.wrapper {
  padding-top: 50px !important;
  padding-bottom: 65px !important;
}

.video-card {
  flex: none;
  .video-title {
    font-size: 0.8rem;
    font-weight: 700;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .video-thumbnail {
    height: 100px;
    object-fit: cover;
    object-position: center center;
  }
  @media screen and (min-width: 320px) {
    width: 139px;
    // height: 180px;
    .video-info {
      font-size: 0.7rem;
    }
  }
  @media screen and (min-width: 768px) {
    width: 250px;
    // height: 230px;
    .video-info {
      font-size: 0.8rem;
    }
  }
}

.stickyNav-top {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
}

.stickyFooter-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 9;
  max-width: 960px;
  height: 36px;
}

.card-shadow {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.carousel-control-prev,
.carousel-control-next {
  display: none;
}

.carousel-dark .carousel-indicators [data-bs-target] {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: var(--bs-gray-800);
}
.carousel-indicators {
  align-items: center;
  // bottom: -70px;
  &::after {
    display: block;
    content: "";
    position: absolute;
    right: -45px;
    color: var(--bs-gray-300);
  }
}

.sideNav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  height: 100%;
  transition: 0.5s;
  overflow: hidden;
  width: 0;
  background-color: $theme-color-1;
  .sideNav-inner {
    background-color: $theme-white;
    padding: 30px 15px;
    height: 150px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    .sideNav-inner-img {
      object-fit: contain;
      object-position: center;
      img {
        display: block;
        height: 50px;
        width: 50px;
      }
    }
  }
  .sideNav-menu {
    padding: 25px 15px;
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: flex-start;
    transition: 0.3s;
    a {
      text-decoration: none;
      font-size: medium;
      font-weight: 400;
      color: $theme-white;
    }
  }
  .sideNav-btn {
    padding: 15px 15px;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  transition: 0.5s;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  z-index: 10;
}

.modal-content {
  background: #212529ad !important;
}

.otpBox {
  height: 2.5em;
  width: 2.5em;
  text-align: center;
}

.btn:disabled {
  background-color: var(--bs-gray-600);
  color: var(--bs-gray-700);
}

.loader {
  z-index: 999;
  background: #d7d7d752;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-containter {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
  @media screen and (max-width: 425px) {
    grid-template-columns: repeat(1, 1fr);
  }
  .video-box {
    min-height: 55px;
    .video-thumbnail {
      width: 55px;
      height: 55px;
    }
  }
}

.signup-name {
  margin-bottom: 30px;
}

.scrollbar {
  scrollbar-width: none;
}

.date-picker {
  border-radius: 5px;
  width: 100%;
  border: 1px solid rgb(20, 199, 20);
  padding: 6px;
  outline: 0ch;
}

.course-btn {
  position: absolute;
  top: 30vh;
}

.text-gray p {
  font-size: 8px;
  font-weight: 200;
  // line-height: 1.3em;
}

.text-gray .last-para {
  transform: translate(0%, -100%);
}

.subtitle {
  font-size: 10px;
  line-height: 1%;
}

.explor {
  height: 85vh;
}
.mycourse {
  height: 100vh;
  background-image: linear-gradient(green, black);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}
.profile-border {
  width: 100%;
  height: 0.5px;
  background: rgb(190, 187, 187);

  margin-top: 30%;
}

.transparent {
  background-color: rgba(43, 42, 42, 0.5);
}
.transparent {
  opacity: 0.5;
}
// .form-check-input{
//   color: green;
// }

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.cardheight {
  height: 90vh;

  overflow: auto;
}
// ///////////////////////

.alert {
  width: 20rem;
  background: #fff;
  padding: 1rem;
  border-radius: 3px;
}
.update-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50vh;
}
.App-header {
  background-color: rgba(16, 15, 15, 0.825);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 960px;
  width: 100%;
  font-size: calc(10px + 2vmin);
  color: white;
  position: absolute;

  z-index: 15;
}
.App-alert {
  position: fixed;
  right: 1rem;
  top: 1rem;
  z-index: 10;
}

.App-alert > * + * {
  margin-top: 1rem;
}
// /////////////////////////////////////////////////
.logout-btn {
  margin: 5%;
}

.rounded-circle {
  width: 6em;
  height: 6em;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.profile-cbody {
  position: relative;
  bottom: 2em;
}

// AppHeader Styling

.appHeader {
  display: flex;
  background-color: $theme-color-3;
  padding: 15px;
  justify-content: start;
  align-items: center;
  z-index: 9;
  width: 100%;
  height: 65px;
  box-shadow: 0 0 15px 5px rgb(0 0 0 / 10%);
  position: fixed;
  top: 0;
  color: var(--bs-light);
  font-size: 1.3rem;
  max-width: 960px;
}

// Carousal Style

.carousel-indicators {
  margin-bottom: 0rem !important;
}

// Types Filter

.types-filter {
  position: fixed;
  bottom: 0;
  display: flex;
  z-index: 99;
  border: none;
  width: 100%;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  height: 0px;
  overflow-y: hidden;
  max-width: 960px;
}
.open {
  height: 100vh;
}

// Setting background for checkboxes

.form-check-input:checked {
  background-color: $button-color-1 !important;
  border-color: $button-color-1 !important;
}

.form-select {
  color: $theme-gray-7 !important;
  border: 1px solid $theme-color-1 !important;
  &:focus {
    color: #212529 !important;
  }
}

// Course Page Style Classes

.course-holder {
  // height: 100vh;
  background-color: #fff;
  .course-image-holder {
    height: 150px;
    object-position: center center;
    object-fit: cover;
    @media screen and (min-width: 768px) {
      height: 250px;
    }
  }
}

// Logout Footer Styling

.logout-container {
  position: absolute;
  bottom: 36px;
  padding: 0px 20px;
  z-index: 99;
}
.splash-screen {
  background-image: url("../Assets/images/splash.png");
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;

  @media screen and (min-width: 426px) {
    background-image: url("../Assets/images/splashWide.png");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
